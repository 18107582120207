/* hero */
.about .hero {
    position: relative;
    height: 98vh;
    background-color: rgba(8, 10, 10, 0.5);
    background-image: url(../../Assets/Images/About-Hero.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: scroll;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    width: 100%;
    background-blend-mode: darken;
    opacity: 1 !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* parallax */
.about .parallax {
    background-image: url(../../Assets/Images/About-Parallax.jpeg);
}