.side-icons {
    position: fixed;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    text-decoration: none;
    color: #000;
    font-size: 24px;
  }
  
  .icon.phone {
    background-color: #e4405f; /* WhatsApp green */
  }
  
  .icon.whatsapp {
    background-color: #e4405f; /* WhatsApp green */
  }
  
  .icon.instagram {
    background-color: #e4405f; /* Instagram pink */
  }

  .icon.email {
    background-color: #e4405f; /* Instagram pink */
  }
  
  @media (max-width: 768px) {
    .icon.phone {
      background-color: #39ef4b; /* WhatsApp green */
    }
    
    .icon.whatsapp {
      background-color: #39ef4b; /* WhatsApp green */
    }
    
    .icon.instagram {
      background-color: #39ef4b; /* Instagram pink */
    }
  
    .icon.email {
      background-color: #39ef4b; 
    }
}

@media (max-width: 600px) {
  .icon.phone {
    background-color: #39ef4b; /* WhatsApp green */
  }
  
  .icon.whatsapp {
    background-color: #39ef4b; /* WhatsApp green */
  }
  
  .icon.instagram {
    background-color: #39ef4b; /* Instagram pink */
  }

  .icon.email {
    background-color: #39ef4b; 
  }
}