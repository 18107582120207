form {
    padding: 20px 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    row-gap: 30px;
}

::placeholder {
    text-transform: capitalize;
    font-family: 'raleway-regular';
}

.form-group {
    width: 48%;
    text-transform: uppercase;
    text-align: left;
}

.form-group abbr {
    font-weight: bold;
    font-size: 18px;
    text-decoration: none;
}

.form-item {
    display: block;
    width: 100%;
    background-color: #f5f5f5;
    margin-top: 8px;
    padding: 14px 10px;
    border: 0.8px solid #dddddd;
    outline: none;
    font-size: 15px;
    transition-duration: 0.25s;
}

.form-item:hover {
    border-color: transparent;
}

.form-group:nth-last-child(2),
.form-group:nth-last-child(3) {
    width: 100%;
}

/* for checkValidity */
.check-valid:invalid {
    border-color: #c80303;
}
.form-group:has(.check-valid:invalid) {
    color: #c80303;
}

/* Responsive */
@media (max-width: 768px) {
    .form-group {
        width: 100%;
    }
}

@media (max-width:600px) {
    form {
        padding: 20px 15px;
    }

    .form-item {
        padding: 12px 8px;
    }
}

@media (max-width:426px) {
    form {
        padding: 20px 0;
    }
}