/* Animations */
@keyframes spin {
    100% {
        transform: rotate(360deg);
    }
}

/* Style */
.parent-loader {
    background-color: var(--secondary-color);
    position: fixed;
    inset: 0;
    z-index: 100;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 30px;
    transition: opacity 0.7s , visibility 0.7s;
}

.loader-img {
    width: 100px;
    height: 100px;
}

.loader {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 2px solid #9b9b9b;
    border-top: 2px solid var(--primary-color);
    animation: spin 1s linear infinite;
}