/* hero */
.services .hero {
    position: relative;
    height: 98vh;
    background-color: rgba(12, 12, 12, 0.5);
    background-image: url(../../Assets/Images/Service-Hero.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: scroll;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    width: 100%;
    background-blend-mode: darken;
    opacity: 1 !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* partners */
.services .partners {
    background-color: var(--bg-color);
    padding-bottom: 0;
}

.services .partners .semicircular::after {
    display: none;
}